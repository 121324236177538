<template>
  <div>
    <Header />
    <div id="smooth-wrapper">
      <div id="smooth-content">
        <main>
          <div class="home-wrapper-4">
            <Hero />
            <Infrastructure />
          </div>
        </main>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/landing/assets/css/bootstrap.min.css";
import "@/assets/landing/assets/css/all.min.css";
import "@/assets/landing/assets/css/custom.css";
import "@/assets/landing/assets/css/master.css";

import Header from "@/views/components/landing/Header.vue";
import Hero from "@/views/components/landing/Hero.vue";
import Infrastructure from "@/views/components/landing/Infrastructure.vue";
import Footer from "@/views/components/landing/Footer.vue";

export default {
  components: {
    Header,
    Hero,
    Infrastructure,
    Footer
  },
  data() {
    return {
    };
  },
  name: "LandingPage",


  methods: {

  },
};
</script>


<style scoped>
.header__area-3 {
  background: #f8f8f8;
}
</style>